import React from 'react';
import { cloneElement } from 'react';
import {
    Filter,
    Show,
    SimpleShowLayout,
    ShowButton,
    ListBase,
    ListToolbar,
    BulkActionsToolbar,
    Pagination,
    useListContext,
    Datagrid,
    TextField,
    NumberField,
    TextInput,
    DateField,
    DateInput,
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    TopToolbar,
    ExportButton,
    sanitizeListRestProps,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {isSuper, isAdmin} from '../../helper';
import StatusField from '../../components/status-field';
import Card from '@material-ui/core/Card';
import DateRangeInput from '../../components/date-range';

const ConversionFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <DateRangeInput source="date_range" label="Transfer Date Range" />
        <SelectInput emptyText="All" source="transfer_status" choices={[
            { id: 'pendiente_conductor', name: 'pending driver' },
            { id: 'asignado_conductor', name: 'assigned driver' },
            { id: 'confirmado_conductor', name: 'confirmed driver' },
            { id: 'completado', name: 'completed' },
            { id: 'cancelado', name: 'canceled' },
        ]} />
        <DateInput source="created_at" />
        <TextInput source="origin" />
        { (isSuper(permissions) || isAdmin(permissions)) &&
        <ReferenceInput label="Partner" emptyText="All partners" source="partner_id" reference="partner">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>}
        <TextInput source="destination" />
        <TextInput source="tracking_id" />
        <NumberInput source="current_revshare_rate" step={1} min={0} max={100} />
        <NumberInput source="id" step={1} min={1} />
        <NumberInput label="Transfer ID" source="trayecto_id" step={1} min={1} />
    </Filter>
);
const Totals = () => {
    const { data, ids } = useListContext();
    return ( 
        <>
        { ids.length > 0 ? <div style={{ width: 200, margin: '1em' }}>
            <Typography variant="h6">Totals:</Typography>
{            <Typography variant="body2">
                <strong>Total transfers cost:</strong> {(ids.map(id => data[id]).reduce((sum, item) => sum + parseFloat(item.transfer_cost), 0)).toFixed(2)}
            </Typography>}
{            <Typography variant="body2">
                <strong>Total revenue:</strong> {(ids.map(id => data[id]).reduce((sum, item) => sum + parseFloat(item.revshare_value), 0)).toFixed(2)}
            </Typography>}
        </div>: ''}
            
        </>
    );
};
const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};
const ListWithTotals = ({ permissions, ...props }) => {
    return (
        <ListBase {...props}>
            <ListToolbar
                filters={ <ConversionFilter permissions={permissions} />}
                actions={<ListActions />}
            />
            <Card>
                <BulkActionsToolbar>
                    {props.bulkActionButtons}
                </BulkActionsToolbar>
                {cloneElement(props.children, {
                    hasBulkActions: false,
                })}
                <Totals />
                <Pagination />
            </Card>
        </ListBase>
)};
export const ConversionList = ({ permissions, ...props }) => (
    <ListWithTotals {...props}
     permissions={permissions}>
        <Datagrid>
            <TextField source="id" />
            { (isSuper(permissions) || isAdmin(permissions))  &&
            <ReferenceField label="Partner" source="partner_id" reference="partner">
                <TextField source="name" />
            </ReferenceField>}
            <TextField source="origin" />
            <TextField source="destination" />
            <TextField label="Transfer ID" source="trayecto_id" />
            <NumberField source="current_revshare_rate" />
            <StatusField source="transfer_status" />
            <NumberField source="transfer_cost" />
            <NumberField sortable={false} source="revshare_value" />
            <DateField sortable={false} showTime label="Transfer date" source="datetime" />
            <DateField showTime source="created_at" />
            <ShowButton />
        </Datagrid>
    </ListWithTotals>
);

export const ConversionShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ReferenceField label="Partner" source="partner_id" reference="partner">
                <TextField source="name" />
            </ReferenceField>}
            <TextField source="tracking_id" />
            <TextField source="origin" />
            <TextField source="destination" />
            <NumberField source="current_revshare_rate" />
            <StatusField source="transfer_status" />
            <TextField label="Transfer ID" source="trayecto_id" />
            <NumberField source="transfer_cost" />
            <NumberField source="revshare_value" />
            <DateField showTime label="Transfer date" source="datetime" />
            <DateField showTime source="created_at" />
        </SimpleShowLayout>
    </Show>
);