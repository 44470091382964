import React from 'react';
import {
    Filter,
    Show,
    Create,
    Edit,
    DeleteButton,
    TabbedForm,
    FormTab,
    ShowButton,
    List,
    EditButton,
    Datagrid,
    TextField,
    NumberField,
    TextInput,
    RichTextField,
    DateField,
    NumberInput,
    required,
    minLength,
    SelectInput,
    TabbedShowLayout,
    Tab,
    Toolbar,
    SaveButton,
    maxLength,
    minValue,
    maxValue,
    email,
    SimpleForm,
    useRecordContext
} from 'react-admin';
import CodeField from '../../components/code-field';
import { isSuper, isAdmin } from '../../helper';
import RichTextInput from 'ra-input-rich-text';
import { countryChoices } from '../../data/CountriesData'
import InputMask from "react-input-mask";
import TokenManager from "../../components/TokenManager";

const validateName = [required(), minLength(2), maxLength(36)];
const validateEmail = [required(), email()];
const validateDescription = [minLength(2), maxLength(2000)];
const validateReference = [required(), minLength(6), maxLength(12)];
const validateRate = [required(), minValue(0), maxValue(100)];
const validatePhone = [required(), minValue(100000000), maxValue(999999999999)];
const validateRequired = [required(), maxLength(2000)];

const PartnerFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
        <TextInput source="reference" />
        <NumberInput source="revshare_rate" step={1} min={0} max={100} />
    </Filter>
);

export const PartnerList = ({ permissions, ...props }) => (
    <List {...props} filters={<PartnerFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />

            <NumberField source="revshare_rate" />
            <TextField source="site.nombre" label="Site" />
            <ShowButton />
            <EditButton />
            {(isSuper(permissions)) &&
                <DeleteButton undoable={false} />}
        </Datagrid>
    </List>
);
const TokensTab = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <TokenManager record={record} />;
};
export const PartnerShow = ({ permissions, ...props }) => (

    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <TextField label="Partner Short Name" source="name" />
                <TextField source="reference" />
                <NumberField source="revshare_rate" />
                <TextField source="site.nombre" label="Site" />
                {(isSuper(permissions) || isAdmin(permissions)) &&
                    <DateField source="created_at" />}
                {(isSuper(permissions) || isAdmin(permissions)) &&
                    <DateField source="updated_at" />}

            </Tab>
            <Tab label="API keys">
                <TokensTab />
            </Tab>
            <Tab label="Company Details">
                <TextField label="Company Name" source="bank_details.company" />
                <TextField label="Country" source="bank_details.country" />
                <TextField label="City" source="bank_details.city" />
                <TextField label="Address" source="bank_details.address" />
                <TextField label="Contact Name" source="bank_details.contact_name" />
                <TextField label="Phone" source="bank_details.phone" />
            </Tab>
            <Tab label="Bank details">
                <TextField label="Bank Account Name" source="bank_details.bank_account_name" />
                <TextField label="IBAN" source="bank_details.iban" />
                <TextField label="Bank Name" source="bank_details.bank_name" />
                <TextField label="Bank Country" source="bank_details.bank_country" />
                <TextField label="Bank Address" source="bank_details.bank_address" />
            </Tab>
            <Tab label="Description">
                <RichTextField label="" source="description" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
const PartnerEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props} >
        <SaveButton redirect="show" />
        { isSuper(permissions) &&
            <DeleteButton />}
    </Toolbar>
);
export const PartnerEdit = ({ permissions, ...props }) => {
    return (<Edit {...props}>
        <TabbedForm toolbar={<PartnerEditToolbar />}>
            <FormTab label="Main">
                <TextInput source="name" validate={validateName} />
                {(isSuper(permissions) || isAdmin(permissions)) &&
                    <TextInput source="reference" validate={validateReference} />}
                {(isSuper(permissions) || isAdmin(permissions)) &&
                    <NumberInput source="revshare_rate" validate={validateRate} />}
                <TextInput source="site_id" label="Site ID" validate={validateRequired} />
            </FormTab>
            <FormTab label="Company Details">
                <TextInput label="Company Name" source="bank_details.company" />
                <SelectInput  choices={countryChoices} optionValue="name" label="Country" source="bank_details.country" />
                <TextInput label="City" source="bank_details.city" />
                <TextInput label="Address" source="bank_details.address" />
                <TextInput label="Contact Name" source="bank_details.contact_name" />
                <NumberInput max="12" min="9" step={1} label="Phone" source="bank_details.phone" />
            </FormTab>
            <FormTab label="Bank details">
                <TextInput label="Bank Account Name" source="bank_details.bank_account_name" />
                <InputMask
                    mask="aa99 9999 9999 9999 9999 99">
                    <TextInput label="IBAN" source="bank_details.iban" />
                </InputMask>
                <TextInput label="Bank Name" source="bank_details.bank_name" />
                <SelectInput choices={countryChoices} optionValue="name" label="Bank Country" source="bank_details.bank_country" />
                <TextInput label="Bank Address" source="bank_details.bank_address" />
            </FormTab>
            <FormTab label="Description">
                <RichTextInput source="description" />
            </FormTab>
        </TabbedForm>
    </Edit>
)}

export const PartnerCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <TabbedForm toolbar={<PartnerEditToolbar />}>
            <FormTab label="Main">
                <TextInput source="name" validate={validateName} />
                {(isSuper(permissions) || isAdmin(permissions)) &&
                    <TextInput source="reference" validate={validateReference} />}
                {(isSuper(permissions) || isAdmin(permissions)) &&
                    <NumberInput source="revshare_rate" validate={validateRate} />}
                <TextInput source="email" validate={validateEmail} />
            </FormTab>
            <FormTab label="Company Details">
                <TextInput label="Company Name" source="bank_details.company" />
                <SelectInput choices={countryChoices} optionValue="name" label="Country" source="bank_details.country" />
                <TextInput label="City" source="bank_details.city" />
                <TextInput label="Address" source="bank_details.address" />
                <TextInput label="Contact Name" source="bank_details.contact_name" />
                <NumberInput max="12" min="9" step={1} label="Phone" source="bank_details.phone" />
            </FormTab>
            <FormTab label="Bank details">
                <TextInput label="Bank Account Name" source="bank_details.bank_account_name" />
                <InputMask
                    mask="aa99 9999 9999 9999 9999 99">
                    <TextInput label="IBAN" source="bank_details.iban" />
                </InputMask>
                <TextInput label="Bank Name" source="bank_details.bank_name" />
                <SelectInput choices={countryChoices} optionValue="name" label="Bank Country" source="bank_details.bank_country" />
                <TextInput label="Bank Address" source="bank_details.bank_address" />
            </FormTab>
            <FormTab label="Description">
                <RichTextInput source="description" validate={validateDescription} />
            </FormTab>
        </TabbedForm>
    </Create>
);
