import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from "../config";
import { useNotify, useRecordContext } from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    Button,
    TextField as MuiTextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';

const TokenManager = () => {
    const record = useRecordContext();
    const notify = useNotify();

    const userId = record.id;
    const authToken = localStorage.getItem('token');

    const [tokens, setTokens] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [expiresAt, setExpiresAt] = useState('');
    const [error, setError] = useState('');
    const [createdToken, setCreatedToken] = useState('');

    const fetchTokens = async (page = 1) => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`${Config.api_url}/partners/tokens/${userId}`, {
                params: {
                    page: page,
                    per_page: 15
                },
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            const data = response.data;
            setTokens(data.data);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
        } catch (err) {
            console.error(err);
            setError('Error fetching tokens');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchTokens();
    }, [userId]);

    const handleCreate = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axios.post(`${Config.api_url}/partners/tokens`, {
                user_id: userId,
                name: name,
                expires_at: expiresAt
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                }
            });
            const tokenData = response.data;
            setCreatedToken(tokenData.access_token);
            setName('');
            setExpiresAt('');
            fetchTokens(currentPage);
            notify('Token created successfully', { type: 'info' });
        } catch (err) {
            console.error(err);
            setError('Error creating token');
        }
    };

    const handleDelete = async (tokenId) => {
        setError('');
        try {
            await axios.delete(`${Config.api_url}/partners/tokens/${tokenId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            fetchTokens(currentPage);
            notify('Token deleted successfully');
        } catch (err) {
            console.error(err);
            setError('Error deleting token');
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            fetchTokens(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < lastPage) {
            fetchTokens(currentPage + 1);
        }
    };

    const copyToClipboard = () => {
        if (createdToken) {
            navigator.clipboard.writeText(createdToken)
                .then(() => {
                    notify('Token copied to clipboard', { type: 'info' });
                })
                .catch((err) => {
                    console.error(err);
                    notify('Failed to copy token', { type: 'warning' });
                });
        }
    };

    return (
        <div>
                <Typography variant="h5" gutterBottom>
                    API Keys
                </Typography>
                {loading && <Typography>Loading tokens...</Typography>}
                {error && <Typography color="error">{error}</Typography>}
                {!loading && tokens.length === 0 && <Typography>No tokens found.</Typography>}
                {!loading && tokens.length > 0 && (
                    <TableContainer component={Paper} style={{ marginTop: 16 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Expires At</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tokens.map((token) => (
                                    <TableRow key={token.id}>
                                        <TableCell>{token.id}</TableCell>
                                        <TableCell>{token.name}</TableCell>
                                        <TableCell>{token.expires_at}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDelete(token.id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!loading && tokens.length > 0 && (
                    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                        <Button onClick={handlePrevious} disabled={currentPage <= 1}>
                            Previous
                        </Button>
                        <Typography variant="body1" style={{ margin: '0 16px' }}>
                            Page {currentPage} of {lastPage}
                        </Typography>
                        <Button onClick={handleNext} disabled={currentPage >= lastPage}>
                            Next
                        </Button>
                    </Box>
                )}
                <Box mt={4} mb={4}>
                    <Typography variant="h6">Create New Token</Typography>
                    <form onSubmit={handleCreate} style={{ marginTop: 16 }}>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs={12} sm={4}>
                                <MuiTextField
                                    label="Token Name"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MuiTextField
                                    label="Expires At"
                                    type="date"
                                    fullWidth
                                    value={expiresAt}
                                    onChange={(e) => setExpiresAt(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    Create Token
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                {createdToken && (
                    <Box mt={4} mb={4}>
                        <Typography variant="subtitle1">
                            Your new token (click to copy):
                        </Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <MuiTextField
                                value={createdToken}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <Button
                                variant="contained"
                                color="default"
                                onClick={copyToClipboard}
                                style={{ marginLeft: 8 }}
                            >
                                Copy
                            </Button>
                        </Box>
                    </Box>
                )}
        </div>
    );
};

export default TokenManager;
