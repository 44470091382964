import React from 'react';
import SolhopLayout from './Layout';
import { Admin, Resource, ListGuesser, ShowGuesser, fetchUtils } from 'react-admin';
import { Route } from 'react-router-dom';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import { UserList, UserShow, UserEdit, UserCreate } from './resources/users/users';
import { PartnerList, PartnerShow, PartnerEdit, PartnerCreate } from './resources/partner/partner';
import { ConversionList, ConversionShow } from './resources/conversion/conversion';
import ProfileEdit from './resources/users/profile';
import PeopleIcon from '@material-ui/icons/People';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ImageIcon from '@material-ui/icons/Image';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EuroIcon from '@material-ui/icons/Euro';
import BookIcon from '@material-ui/icons/LibraryBooks';
import { isSuper, isAdmin, isPartner, isAll } from './helper';
import Login from './Login';
import ForgotPass from './ForgotPass';
import SetPass from './SetPass';
import Register from './Register';

import {
    PageContentEdit,
    PageContentCreate,
    PageContentList,
    PageContentShow,
} from "./resources/page-content/";

import {
    LocationList,
    LocationShow,
} from "./resources/location/";

import {
    GalleryList,
    GalleryShow,
    GalleryEdit,
    GalleryCreate
} from './resources/gallery/'

import {
    TariffsCreate,
    TariffsEdit,
    TariffsList,
    TariffsShow
} from './resources/tariffs'

import {
    DictionaryCreate,
    DictionaryEdit,
    DictionaryList,
    DictionaryShow
} from './resources/dictionary'

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const messages = {
    en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en', { allowMissing: true, onMissingKey: (key, _, __) => key });

const App = () => (
    <Admin 
        i18nProvider={i18nProvider}
        layout={SolhopLayout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={Login}
        customRoutes={[
            <Route
                exact
                path="/profile"
                render={(props) => (
                    <ProfileEdit {...props} />
                )}
            />,
            <Route
                path="/reset-password"
                exact
                noLayout
                render={(props) => (
                    <ForgotPass {...props} />
                )}
            />,
            <Route
                path="/set-password"
                exact
                noLayout
                render={(props) => (
                    <SetPass {...props} />
                )}
            />,
            <Route
                path="/register"
                exact
                noLayout
                render={(props) => (
                    <Register {...props} />
                )}
            />
        ]}>
        {permissions => [


            isAll(permissions)
                ? <Resource name="conversion" title="Conversions" list={ConversionList} show={ConversionShow} icon={ShowChartIcon} /> : null,
            isPartner(permissions)
                ? <Resource options={{ label: 'Settings' }} name="partner" show={PartnerShow} edit={PartnerEdit} icon={SettingsIcon} /> : null,
            isSuper(permissions) || isAdmin(permissions)
                ? <Resource name="partner" list={PartnerList} show={PartnerShow} edit={PartnerEdit} create={PartnerCreate} icon={EmojiPeopleIcon} /> : null,
            isSuper(permissions) || isPartner(permissions)
                ? <Resource name="user" list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} icon={PeopleIcon} /> : null,
            isSuper(permissions) || isAdmin(permissions)
                ? <Resource name="location" list={LocationList} show={LocationShow} icon={LocationOnIcon} /> : null,
            isSuper(permissions) || isAdmin(permissions)
                ? <Resource name="page_content" options={{ label: 'Page Content' }} list={PageContentList} show={PageContentShow} edit={PageContentEdit} create={PageContentCreate} /> : null,
            isAll(permissions)
                ? <Resource name="gallery" options={{ label: 'Gallery' }} list={GalleryList} show={GalleryShow} edit={GalleryEdit} create={GalleryCreate} icon={ImageIcon} /> : null,
            isAll(permissions)
                ? <Resource name="tarif" options={{ label: 'Tariffs' }} list={TariffsList} show={TariffsShow} edit={TariffsEdit} create={TariffsCreate} icon={EuroIcon}/> : null,
            isAll(permissions)
                ? <Resource name="diccionario" options={{ label: 'Dictionary' }} list={DictionaryList} edit={DictionaryEdit} show={DictionaryShow} create={DictionaryCreate} icon={BookIcon}/> : null,
            isAll(permissions)
                ? <Resource name="vehicle"/> : null,

            isAll(permissions)

                ? <Resource name="role" /> : null,
            <Resource name="profile" />,
            <Resource name="reset-password" />,
            <Resource name="register" />,
            <Resource name="set-password" />,
        ]}
    </Admin>
);

export default App;
