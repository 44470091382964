import React from 'react';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LogoMain from './LogoMain';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import {
    useLogin,
    fetchStart,
    fetchEnd,
    useNotify,
    Notification,
    TextInput,
    PasswordInput,
    SimpleForm,
    Toolbar,
    NumberInput,
    SelectInput,
    required,
    minLength,
    maxLength,
    email,
} from 'react-admin';
import Config from './config';
import {countryChoices} from './data/CountriesData'
import InputMask from "react-input-mask";
import { BooleanInput } from 'react-admin';
import PolicyModal from './components/PolicyModal'

const validateName = [required(), minLength(2), maxLength(36)];
const validateEmail = [required(), email()];
const validateRequired = [required(), maxLength(2000)];

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.solhop.com/">
                Solhop
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    country: {
        color: 'currentColor',
        width: '100%',
        height: '56px',
        padding: '14px 10px',
        margin: 0,
        display: 'block',
        background: 'none',
        boxSizing: 'border-box',
        animationName: 'mui-auto-fill-cancel',
        letterSpacing: 'inherit',
        animationDuration: '10ms',
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        fontFamily: 'inherit',
        fontSize: '16px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        backgroundColor: 'whitesmoke'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    toolbar: {
        paddingLeft: '16px',
        paddingRight: '16px'
    }
}));

export default function Register() {
    const classes = useStyles();

    const [userdata, setUserData] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [isError, setIsError] = useState(true);

    const dispatch = useDispatch();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const login = useLogin();

    const validateData = (values) => {
        const errors = {};
        setIsError(false);
        if (!values.email) {
            errors.email = ['The email is required'];
            setIsError(true);
        }
        if (!values.password) {
            errors.password = ['The password is required'];
            setIsError(true);
        }
        if (!values.password_check) {
            errors.password = ['The password repeat is required'];
            setIsError(true);
        }
        if (values.password_check !== values.password) {
            errors.password = ["Passwords don't match"];
            errors.password_check = ["Passwords don't match"];
            setIsError(true);
        }
        if (!values.address) {
            errors.address = ['The address is required'];
            setIsError(true);
        }
        if (!values.company) {
            errors.company = ['The company is required'];
            setIsError(true);
        }
        if (!values.city) {
            errors.city = ['The city is required'];
            setIsError(true);
        }
        if (!values.phone) {
            errors.phone = ['The phone is required'];
            setIsError(true);
        } else {
            var stringPhone = values.phone.toString();
            if (stringPhone.length < 9) {
                errors.phone = ['The phone is too short'];
                setIsError(true);
            }
            if (stringPhone.length > 12) {
                errors.phone = ['The phone is too long'];
                setIsError(true);
            }
        }
        if (!values.bank_account_name) {
            errors.bank_account_name = ['The bank account name is required'];
            setIsError(true);
        }
        if (!values.bank_address) {
            errors.bank_address = ['The bank address is required'];
            setIsError(true);
        }
        if (!values.bank_country) {
            errors.bank_country = ['The bank country is required'];
            setIsError(true);
        }
        if (!values.contact_name) {
            errors.contact_name = ['The contact name is required'];
            setIsError(true);
        }
        if (!values.iban) {
            errors.iban = ['The IBAN is required'];
            setIsError(true);
        }
        if (!values.bank_name) {
            errors.bank_name = ['The bank name is required'];
            setIsError(true);
        }
        if (!values.policy) {
            errors.policy = ['Please, agree with Solhop Partner Affiliate Terms & Conditions'];
            setIsError(true);
        }
        return errors
    };

    const submit = (e) => {
        e.preventDefault();
        dispatch(fetchStart()); // start the global loading indicator 
        const url = Config.web_url + "/register_partner";
        console.log(userdata);
        let data = userdata;
        data.bank_details = bankDetails;
        data.name = bankDetails.company;
        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept':'application/json' },
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    let username = data.email;
                    let password = data.password;
                    console.log(username, password);
                    login({ username, password })
                        .catch(() => notify('Invalid parameters'));
                }
            })
            .catch((e) => {
                console.log(e);
                notify('Error: Invalid parameters!', 'warning')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <LogoMain />
                <Typography component="h1" variant="h5">
                    Create a partner account
                </Typography>
                <SimpleForm
                className={classes.form}
                validate={validateData}
                toolbar={(
                    <Toolbar className={classes.toolbar}>
                      <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading || isError}
                            color="primary"
                            className={classes.submit}
                        >
                            Create account
                      </Button>
                    </Toolbar>
                  )}
                onSubmit={submit}>
                    <Typography component="h3" variant="subtitle1">
                        Create login details
                    </Typography>
                    <TextInput
                        variant="outlined"
                        id="email"
                        margin="normal"
                        fullWidth
                        validate={validateEmail}
                        helperText=""
                        label="Email"
                        name="email"
                        value={userdata.email}
                        onChange={e => setUserData({...userdata, email: e.target.value})}
                    />
                    <PasswordInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        helperText=""
                        value={userdata.password}
                        onChange={e => setUserData({...userdata, password: e.target.value})}
                        name="password"
                        label="Password"
                    />
                    <PasswordInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password_check"
                        helperText=""
                        value={userdata.password_check}
                        onChange={e => setUserData({...userdata, password_check: e.target.value})}
                        name="password_check"
                        label="Repeat Password"
                    />
                    <Typography component="h3" variant="subtitle1">
                        Company details
                    </Typography>
                    <TextInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="company"
                        validate={validateName}
                        helperText=""
                        value={bankDetails.company}
                        onChange={e => setBankDetails({...bankDetails, company: e.target.value}) }
                        name="company"
                        label="Company Name"
                    />
                    <SelectInput
                        id="country"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className="country"
                        validate={validateRequired}
                        helperText=""
                        choices={countryChoices}
                        value={bankDetails.country}
                        optionValue="name"
                        onChange={e => setBankDetails({...bankDetails, country: e.target.value}) }
                        name="country"
                        label="Country"
                    />
                    <TextInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="city"
                        validate={validateName}
                        helperText=""
                        value={bankDetails.city}
                        onChange={e => setBankDetails({...bankDetails, city: e.target.value}) }
                        name="city"
                        label="City"
                    />
                    <TextInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="address"
                        validate={validateRequired}
                        helperText=""
                        value={bankDetails.address}
                        onChange={e => setBankDetails({...bankDetails, address: e.target.value}) }
                        name="address"
                        label="Company Address"
                    />
                    <TextInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="contact_name"
                        validate={validateName}
                        helperText=""
                        value={bankDetails.contact_name}
                        onChange={e => setBankDetails({...bankDetails, contact_name: e.target.value}) }
                        name="contact_name"
                        label="Contact Name"
                    />
                    <NumberInput
                        variant="outlined"
                        margin="normal"
                        validate={validateRequired}
                        fullWidth
                        step={1}
                        helperText=""
                        id="phone"
                        value={bankDetails.phone}
                        onChange={e => setBankDetails({...bankDetails, phone: e.target.value}) }
                        name="phone"
                        label="Phone number">
                    </NumberInput>
                    <Typography component="h3" variant="subtitle1">
                        Bank details
                    </Typography>
                    <TextInput
                        variant="outlined"
                        id="bank_account_name"
                        margin="normal"
                        fullWidth
                        validate={validateName}
                        helperText=""
                        label="Bank Account Name"
                        name="bank_account_name"
                        value={bankDetails.bank_account_name}
                        onChange={e => setBankDetails({...bankDetails, bank_account_name: e.target.value})}
                    />
                    <InputMask
                        mask="aa99 9999 9999 9999 9999 99"
                        value={bankDetails.iban} 
                        fullWidth
                        variant="outlined"
                        id="iban"
                        validate={validateRequired}
                        name="iban"
                        helperText=""
                        label="IBAN"
                        margin="normal"
                        onChange={e => setBankDetails({...bankDetails, iban: e.target.value})}>
                        <TextInput value={bankDetails.iban}  />
                    </InputMask>
                    <TextInput
                        variant="outlined"
                        id="bank_name"
                        margin="normal"
                        fullWidth
                        validate={validateName}
                        helperText=""
                        label="Bank Name"
                        name="bank_name"
                        value={bankDetails.bank_name}
                        onChange={e => setBankDetails({...bankDetails, bank_name: e.target.value})}
                    />
                    <SelectInput
                        id="bank_country"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className="bank_country"
                        validate={validateRequired}
                        helperText=""
                        choices={countryChoices}
                        value={bankDetails.bank_country}
                        optionValue="name"
                        onChange={e => setBankDetails({...bankDetails, bank_country: e.target.value}) }
                        name="bank_country"
                        label="Bank Country"
                    />
                    <TextInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="bank_address"
                        validate={validateRequired}
                        helperText=""
                        value={bankDetails.bank_address}
                        onChange={e => setBankDetails({...bankDetails, bank_address: e.target.value}) }
                        name="bank_address"
                        label="Bank Address"
                    />
                    
                    <span>I agree to the <PolicyModal /></span>
                    <BooleanInput label="" value={userdata.policy} name="policy"/>

                </SimpleForm>
                <Notification />
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}