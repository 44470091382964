import * as React from "react";
import { useState, useEffect } from 'react';
import {
  Edit,
  TextInput,
  TextField,
  SimpleForm
} from "react-admin";



export const TariffsEdit = ({ ...props }) => {
  return(
    <Edit {...props}>
      <SimpleForm>
        <TextField label="ID" source="id"/>
        <TextField label="Route" source="route.name"/>
        <TextField label="Vehicle" source="vehicle.name"/>
        <TextInput label="Tariff" source="singlePrice" />
        <TextInput label ="Discount" source="discount" />
      </SimpleForm>
    </Edit>
  )
};
