import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm
} from "react-admin";



export const DictionaryEdit = ({ ...props }) => {
  return(
    <Edit {...props}>
      <SimpleForm>
        <TextInput label="Key" source="id"/>
        <TextInput label="English" source="english" />
        <TextInput label="Spanish" source="spanish" />
        <TextInput label="German" source="german" />
      </SimpleForm>
    </Edit>
  )
};
