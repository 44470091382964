import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  Filter,
  TextInput,
  DeleteButton,
} from "react-admin";


const PostFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Key" source="id" alwaysOn />
        <TextInput label="English" source="english" alwaysOn />
        <TextInput label="Spanish" source="spanish" alwaysOn />
        <TextInput label="Germany" source="german" alwaysOn/>
    </Filter>
  );

export const DictionaryList = (props) => (

  <List {...props} filters={<PostFilters/>} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField label="Key" source="id" />
      <TextField label="English" source="english" sortable={false} />
      <TextField label="Spanish" source="spanish" sortable={false} />
      <TextField label="German" source="german" sortable={false} />
      <ShowButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
