import React from "react";
import { Show, SimpleShowLayout, TabbedShowLayout, Tab, ReferenceField, TextField } from "react-admin";

export const TariffsShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField label="Route" source="route.name" /> 
        <TextField label="Vehicle" source="vehicle.name" />
        <TextField label="Tariff" source="singlePrice" />
        <TextField label ="Discount" source="discount" />
      </SimpleShowLayout>
    </Show>
  );
};
