import React from 'react';
import {
    Create,
    TextInput,
    SimpleForm
} from 'react-admin';


export const DictionaryCreate = ({...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Key" source="id"  />
            <TextInput label="English" source="english"  />
            <TextInput label="Spanish" source="spanish"  />
            <TextInput label="German" source="german" />
        </SimpleForm>
    </Create>
);