import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  Filter,
  TextInput,
  DeleteWithConfirmButton,
  useUpdate,
  useRefresh,
  useNotify,
  useUnselectAll,
  Pagination,
  AutocompleteInput,
  ReferenceInput,
  required
} from "react-admin";

import { Fragment } from 'react';


const EditDiscountButton = ({ selectedIds, ...props }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    let inputValue = () => {
        return document.querySelector('#discountValue').value;
    };
    const [update, { loading, error }] = useUpdate();
    const handleClick = () => {
        let diff = {discount: inputValue()}
        for (var i=0; i<selectedIds.length; i++) {
            update('tarif', selectedIds[i], diff)
        }
        refresh()
        unselectAll()
    }
    if (error) { return <p>ERROR</p>; }
    return (
            <div>
                <form action="#">
                    <input id="discountValue" type="number" onChange={inputValue} ></input>
                    <button onClick={handleClick} >discount!</button>
                </form>
            </div>
    );
};

const PostBulkActionButtons = props => (
    <Fragment>
        <EditDiscountButton label="+5% discount test!" {...props} />
    </Fragment>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[100, 300, 1000]} {...props} />;

const PostFilters = (props) => (
    <Filter {...props}>
        <TextInput label="ID" source="id"/>
        <TextInput label="Origin" source="originName" />
        <ReferenceInput label="Origin point" source="originNameAC" reference="location" perPage={100} allowEmpty alwaysOn
        filterToQuery={searchText => ({ nombre: searchText })}
        sort={{ field: 'id', order: 'ASC' }}>
          <AutocompleteInput optionText="nombre" optionValue="id" emptyValue={null}/>
        </ReferenceInput>
        <TextInput label="Destination" source="destinationName" />
        <ReferenceInput label="Destination point" source="destinationNameAC" reference="location" perPage={100} allowEmpty alwaysOn
        filterToQuery={searchText => ({ nombre: searchText })}
        sort={{ field: 'id', order: 'ASC' }}>
          <AutocompleteInput optionText="nombre" optionValue="id" emptyValue={null}/>
        </ReferenceInput>
        <ReferenceInput label="Vehicle" source="vehicle_id" reference="vehicle" allowEmpty alwaysOn
        filterToQuery={searchText => ({ name: searchText })}
        sort={{ field: 'id', order: 'ASC' }}>
            <AutocompleteInput optionText="name" optionValue="id" emptyValue={null}/>
        </ReferenceInput>
        <TextInput label="Tariff" source="singlePrice" />
        <TextInput label="Discount" source="discount"/>
    </Filter>
  );

export const TariffsList = (props) => (

  <List {...props} filters={<PostFilters/>} bulkActionButtons={<PostBulkActionButtons/>} perPage={100} pagination={<PostPagination />}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="From" source="route.originName" />
      <TextField label="To" source="route.destinationName" />
      <TextField label="Vehicle" source="vehicle.name" />
      <TextField label="Tariff" source="singlePrice" />
      <TextField label="Discount" source="discount" />
      <ShowButton />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);
