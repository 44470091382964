import React from "react";
import { Show, SimpleShowLayout, TabbedShowLayout, Tab, ReferenceField, TextField } from "react-admin";

export const DictionaryShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Key" source="id" />
        <TextField label="English" source="english" /> 
        <TextField label="Spanish" source="spanish" />
        <TextField label="German" source="german" />
      </SimpleShowLayout>
    </Show>
  );
};
