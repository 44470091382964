import * as React from "react";
import {
    Create,
    TextInput,
    SelectInput,
    NumberInput,
    SimpleForm,
    AutocompleteInput,
    ReferenceInput,
    required
} from 'react-admin';

export const TariffsCreate = ({ ...props }) => {
    return (
        <Create title="New Tariff" {...props} >
            <SimpleForm {...props}>
                
                <ReferenceInput label="Origin" source="origin_id" reference="location" 
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText="nombre" optionValue="id" validate={required()}/>
                </ReferenceInput>

                <ReferenceInput label="Destination" source="destination_id" reference="location" 
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText="nombre" optionValue="id" validate={required()}/>
                </ReferenceInput>

                <ReferenceInput label="Vehicle" source="vehicle_id" reference="vehicle" 
                filterToQuery={searchText => ({ name: searchText })}
                sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText="name" optionValue="id" validate={required()}/>
                </ReferenceInput>

                <NumberInput label="Tariff" source="singlePrice" />
                <NumberInput label="Discount" source="discount" />
            </SimpleForm>
        </Create>
    );
}